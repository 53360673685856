<template>
  <div v-if="maxPage > 0" class="pagination" :class="{ 'u-m-t-sm': maxPage === 1 }">
    <div class="pagination-numbers" :class="{ 'in-company-selector': returnEvent }">
      <NuxtLinkLocale v-if="page != 1" :to="linkForPage(1)" @click="returnPage(1)">1</NuxtLinkLocale>
      <span v-if="page - pageOffset > 2" class="suspensions">...</span>
      <NuxtLinkLocale
        v-for="i in pageRange"
        :key="i"
        :to="linkForPage(i)"
        :class="{ current: page == i }"
        @click="returnPage(i)"
        >{{ i }}</NuxtLinkLocale
      >
      <span v-if="page + pageOffset < maxPage - 1" class="suspensions">...</span>
      <NuxtLinkLocale
        v-for="i in tensPages"
        v-show="!isXs && showTens"
        :key="i"
        :to="linkForPage(i)"
        :class="{ current: page == i }"
        @click="returnPage(i)"
        >{{ i }}</NuxtLinkLocale
      >
      <NuxtLinkLocale
        v-for="i in hundredsPages"
        v-show="!isXs && showHundreds"
        :key="i"
        :to="linkForPage(i)"
        :class="{ current: page == i }"
        @click="returnPage(i)"
        >{{ i }}</NuxtLinkLocale
      >
      <NuxtLinkLocale v-if="page != maxPage" :to="linkForPage(maxPage)" @click="returnPage(maxPage)">{{
        maxPage
      }}</NuxtLinkLocale>
    </div>
    <div class="pagination-prevnext">
      <NuxtLinkLocale
        v-if="currentPage !== 1"
        :to="linkForPage(prevPage)"
        class="prev"
        :class="{ full: isXs && currentPage === maxPage }"
        @click="returnPage(prevPage)"
      >
        <div class="icon-angle-left u-bg-steel-grey" />
        <span>{{ $t('actions.previous') }}</span>
      </NuxtLinkLocale>
      <NuxtLinkLocale
        v-if="currentPage !== maxPage"
        :to="linkForPage(nextPage)"
        class="next"
        :class="{ full: isXs && currentPage === 1 }"
        @click="returnPage(nextPage)"
      >
        <span>{{ $t('actions.next') }}</span>
        <div class="icon-angle-right u-bg-steel-grey" />
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup>
import { debounce } from '@/utils'

const { isSm, isXs } = useDeviceSize()

const props = defineProps({
  currentPage: { type: Number, default: 1 },
  maxPage: { type: Number, default: 1 },
  queryPageName: { type: String, default: 'page' },
  returnEvent: { type: Boolean, default: false },
})
const emit = defineEmits(['update-page'])
const pageOffset = ref(4)
const showTens = ref(true)
const showHundreds = ref(true)

const page = computed(() => {
  let boundedPage = props.currentPage
  boundedPage = Math.max(1, boundedPage)
  boundedPage = Math.min(props.maxPage, boundedPage)
  return boundedPage
})
const prevPage = computed(() => {
  return Math.max(1, page.value - 1)
})
const nextPage = computed(() => {
  return Math.min(props.maxPage, page.value + 1)
})
const pageRange = computed(() => {
  const range = []
  for (let i = pageOffset.value; i > 0; i -= 1) {
    if (page.value - i > 1) {
      range.push(page.value - i)
    }
  }
  range.push(page.value)
  for (let i = 1; i <= pageOffset.value; i += 1) {
    if (page.value + i < props.maxPage) {
      range.push(page.value + i)
    }
  }
  return range
})
const tensPages = computed(() => {
  const maxI = Math.min(props.maxPage, 99)
  const tens = []
  for (let i = 10; i <= maxI; i += 10) {
    if (
      i !== props.maxPage &&
      i > props.currentPage &&
      !(i >= props.currentPage - pageOffset.value && i <= props.currentPage + pageOffset.value) // Check if not already existing in offset pages
    )
      tens.push(i)
  }
  return tens
})
const hundredsPages = computed(() => {
  const maxI = Math.min(props.maxPage, 1000)
  const hundreds = []
  for (let i = 100; i <= maxI; i += 100) {
    if (
      i !== props.maxPage &&
      i > props.currentPage &&
      !(i >= props.currentPage - pageOffset.value && i <= props.currentPage + pageOffset.value) // Check if not already existing in offset pages
    )
      hundreds.push(i)
  }
  return hundreds
})
onBeforeMount(() => {
  if (!props.returnEvent) window.addEventListener('resize', debounce(matchFullWidth, 300), { passive: true })
})
onBeforeUnmount(() => {
  if (!props.returnEvent) window.removeEventListener('resize', matchFullWidth)
})
onMounted(() => {
  if (!props.returnEvent) {
    setTimeout(() => {
      matchFullWidth()
    }, 200)
  }
})

const linkForPage = page => {
  // No query if from company selector, empty link
  if (props.returnEvent) return ''
  const route = useRoute()
  const { name, params, query } = route
  const newQuery = { ...query, [props.queryPageName]: page }
  return { name, query: newQuery, params }
}
const returnPage = page => {
  if (props.returnEvent) emit('update-page', page)
}
const matchFullWidth = () => {
  // TODO store width and only fire with width change
  if (!props.returnEvent) {
    // Fill or limit pagination numbers according to pagination div width
    // Get all informations
    const paginationWidth = document.querySelector('.pagination')?.offsetWidth
    const numberWidth = 30 // css a width
    const spacing = 10 // gap flex
    const numbersNeeded = Math.floor((paginationWidth - spacing) / (numberWidth + spacing))
    const paginationElements = document.querySelector('.pagination-numbers')?.childElementCount
    // Hide tens & hundreds in Sm
    if (isSm.value) {
      showTens.value = false
      showHundreds.value = false
    }
    // Then calculate displayed elems according to tens/hundred or not
    const displayedTens = showTens.value ? 0 : tensPages.value.length
    const displayedHundreds = showHundreds.value ? 0 : hundredsPages.value.length
    const displayedElements = paginationElements - displayedHundreds - displayedTens
    // Get missing or excess numbers, and adjust pageOffset ( -1 is for security)
    const missingNumbers = numbersNeeded - displayedElements
    const excessNumbers = displayedElements - numbersNeeded
    if (numbersNeeded > props.maxPage) {
      pageOffset.value = props.maxPage
    }
    if (missingNumbers > 0 && numbersNeeded < props.maxPage) {
      const newPageoffset = pageOffset.value + Math.floor(missingNumbers / 2) - 1
      pageOffset.value = newPageoffset
      if (isXs.value && newPageoffset > 2 && !(props.currentPage < 2 || props.currentPage > props.maxPage - 2)) {
        pageOffset.value = 2
      }
    } else if (excessNumbers >= 0) {
      const newPageoffset = pageOffset.value - Math.floor(excessNumbers / 2) - 1
      pageOffset.value = newPageoffset
      if (isXs.value && newPageoffset > 2 && !(props.currentPage < 2 || props.currentPage > props.maxPage - 2)) {
        pageOffset.value = 2
      }
    }
  }
}
</script>

<style lang="scss">
.pagination {
  text-align: center;
  margin: 30px 0 0;
  padding: 0 $spacing-md;

  a {
    text-decoration: none;
    line-height: 30px;
  }

  &-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacing-sm;

    &.in-company-selector {
      justify-content: unset;
    }

    a {
      background-color: transparent;
      border-radius: 22px;
      color: var(--steel-grey);
      display: inline-block;
      font-size: pxToRem(15px);
      width: 30px;
      height: 30px;
      transition:
        background-color var(--transition-duration) var(--transition-timing-function),
        color var(--transition-duration) var(--transition-timing-function);

      &.disabled {
        color: var(--steel-grey);
        font-family: 'Avenir';
        font-weight: normal;
        opacity: 0.5;
        pointer-events: none;
      }

      &.current,
      &.current:visited {
        background-color: var(--night-blue);
        color: var(--white);
      }
    }

    & > a:visited {
      color: var(--light-grey);
    }

    & > a:hover {
      color: var(--dark-blue);
      background-color: var(--bg-grey);
    }
  }

  &-prevnext {
    margin-top: $spacing-md;
    display: flex;
    justify-content: center;
    gap: $spacing-md;
    a {
      color: var(--dark-blue);
      background-color: var(--bg-grey);
      border-radius: 5px;
      width: auto;
      height: auto;
      padding: 0 $spacing-xs;
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      @include mq($mq-xs) {
        width: 50%;

        &.full {
          width: 100%;
          justify-content: center;

          span {
            width: unset;
          }
        }
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      &.prev:hover {
        div {
          transform: translateX(5px);
        }
      }

      &.next:hover {
        div {
          transform: translateX(-5px);
        }
      }

      span {
        padding: 0 $spacing-sm;
        min-width: 85px;

        @include mq($mq-xs) {
          width: 80%;
        }
      }

      div {
        -webkit-mask-size: 23px;
        transition: transform 0.2s ease;
      }
    }
  }
}
.submenu .company-selector {
  .pagination {
    margin: $spacing-md;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $spacing-sm;
    a {
      display: inline-block;
      font-size: pxToRem(14px);
      font-weight: normal;
      background-color: var(--bg-grey);
      padding: 0;
      margin: 0;
      &.current {
        background-color: var(--night-blue);
        color: var(--white);
        font-weight: 900;
      }

      div {
        display: none;
      }
    }
    &-prevnext {
      width: 100%;
      justify-content: flex-start;
      margin-top: 0;
      gap: $spacing-sm;

      a {
        width: auto;
        height: auto;
      }
    }
  }
}
</style>
